import { ref } from 'vue'
import { Modal, Radio, Button } from 'account-ui-components'
import appStoreIcon from '@/assets/img/app-store-light.svg'
import googlePlayIcon from '@/assets/img/google-play-light.svg'
import iosGoogleAuthQrIcon from '@/assets/img/ios-google-auth-qr.svg'
import iosMicrosoftAuthQrIcon from '@/assets/img/ios-microsoft-auth-qr.svg'
import iosOtherAuthAppsQrIcon from '@/assets/img/ios-other-auth-apps-qr.svg'
import androidGoogleAuthQrIcon from '@/assets/img/android-google-auth-qr.svg'
import androidMicrosoftAuthQrIcon from '@/assets/img/android-microsoft-auth-qr.svg'
import androidOtherAuthAppsQrIcon from '@/assets/img/android-other-auth-apps-qr.svg'

export default {
  name: 'ModalRecommendedAuthApps',

  props: {
    operatingSystem: {
      type: String,
      default: 'ios'
    },
    onClose: {
      type: Function,
      required: true
    }
  },

  components: {
    Modal,
    Radio,
    Button
  },

  setup() {
    const selectedApp = ref('google')
    const images = {
      ios: appStoreIcon,
      android: googlePlayIcon
    }
    const apps = {
      google: {
        value: 'google',
        label: 'Google Authenticator',
        os: {
          ios: {
            qrCodeIcon: iosGoogleAuthQrIcon,
            link: 'https://apps.apple.com/us/app/google-authenticator/id388497605'
          },
          android: {
            qrCodeIcon: androidGoogleAuthQrIcon,
            link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
          }
        }
      },
      microsoft: {
        value: 'microsoft',
        label: 'Microsoft Authenticator',
        os: {
          ios: {
            qrCodeIcon: iosMicrosoftAuthQrIcon,
            link: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458'
          },
          android: {
            qrCodeIcon: androidMicrosoftAuthQrIcon,
            link: 'https://play.google.com/store/apps/details?id=com.azure.authenticator'
          }
        }
      },
      other: {
        value: 'other',
        label: 'Other apps',
        os: {
          ios: {
            qrCodeIcon: iosOtherAuthAppsQrIcon,
            link: 'https://apps.apple.com/'
          },
          android: {
            qrCodeIcon: androidOtherAuthAppsQrIcon,
            link: 'https://play.google.com/store/search?q=authentication&c=apps'
          }
        }
      }
    }
    const options = Object.keys(apps)

    const handleRadioChange = (event) => {
      const { value } = event.target

      selectedApp.value = value
    }

    return {
      selectedApp,
      images,
      apps,
      options,
      handleRadioChange
    }
  }
}
