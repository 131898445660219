import { ref, reactive, onMounted } from 'vue'
import { Preloader, Button } from 'account-ui-components'
import { preloadImages } from '@/helpers'
import ModalRecommendedAuthApps from '@/components/_modals/ModalRecommendedAuthApps/ModalRecommendedAuthApps.vue'
import googlePlayIcon from '@/assets/img/google-play.svg'
import appStoreIcon from '@/assets/img/app-store.svg'

export default {
  name: 'DownloadAuthenticatorApp',

  props: {
    onBackClick: {
      type: Function,
      required: true
    },
    onNextClick: {
      type: Function,
      required: true
    }
  },

  components: {
    Preloader,
    Button,
    ModalRecommendedAuthApps
  },

  setup() {
    const loading = reactive({
      component: true
    })
    const modals = reactive({
      recommendedApps: false
    })
    const selectedOS = ref(null)
    const images = {
      googlePlay: googlePlayIcon,
      appStore: appStoreIcon
    }
    const operationSystems = [
      {
        value: 'ios',
        icon: images.appStore
      },
      {
        value: 'android',
        icon: images.googlePlay
      }
    ]

    onMounted(async () => {
      await preloadImages(Object.values(images))

      loading.component = false
    })

    const toggleModal = (modal) => {
      modals[modal] = !modals[modal]
    }

    const handleOSClick = (value) => {
      selectedOS.value = value

      toggleModal('recommendedApps')
    }

    return {
      loading,
      modals,
      images,
      operationSystems,
      selectedOS,
      toggleModal,
      handleOSClick
    }
  }
}
