<template>
  <div class="download-authenticator-app">
    <div
      v-if="loading.component"
      class="download-authenticator-app__preloader-container"
    >
      <Preloader />
    </div>

    <template v-else>
      <div class="download-authenticator-app__title">
        Download or use an authenticator app on your phone
      </div>
      <div class="download-authenticator-app__text">
        An authenticator app generates a
        <span class="download-authenticator-app__bold-text">one-time code</span>
        for secure login.
      </div>
      <!-- prettier-ignore -->
      <div class="download-authenticator-app__text">
        View recommended
        <span class="download-authenticator-app__bold-text">authenticator apps to download</span>:
      </div>

      <div class="download-authenticator-app__apps-container">
        <div
          v-for="os in operationSystems"
          :key="os.value"
          class="download-authenticator-app__apps-item"
        >
          <div
            class="download-authenticator-app__app-icon-holder"
            @click="
              () => {
                handleOSClick(os.value)
              }
            "
          >
            <img
              :src="os.icon"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="download-authenticator-app__buttons-container">
        <div class="download-authenticator-app__button-holder">
          <Button
            variant="outlined"
            :onClick="onBackClick"
          >
            Back
          </Button>
        </div>
        <div class="download-authenticator-app__button-holder">
          <Button
            variant="outlined"
            :onClick="onNextClick"
          >
            I have an app
          </Button>
        </div>
      </div>
    </template>

    <modal-recommended-auth-apps
      v-if="modals.recommendedApps"
      :operating-system="selectedOS"
      :on-close="
        () => {
          toggleModal('recommendedApps')
        }
      "
    ></modal-recommended-auth-apps>
  </div>
</template>

<script src="./DownloadAuthenticatorApp.js"></script>

<style src="./DownloadAuthenticatorApp.scss" lang="scss" scoped></style>
