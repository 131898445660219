import { ref, reactive, computed, nextTick, onMounted } from 'vue'
import { Preloader, Input, PasswordInput, Button, Link } from 'account-ui-components'
import Api from '@/api'
import {
  validateField,
  validateRequired,
  validateEmail,
  validateCaptchaTokenExpired,
  validateCaptchaTokenExists
} from '@/helpers/validators'
import { getEnv } from '@/helpers'
import Page from '@/components/Page/Page.vue'
import Captcha from '@/components/Captcha/Captcha.vue'
import ModalEmailAlreadyInUse from '@/components/_modals/ModalEmailAlreadyInUse/ModalEmailAlreadyInUse.vue'
import arrowIcon from '@/assets/img/arrow.svg'
import userIcon from '@/assets/img/user.svg'

export default {
  name: 'Login',

  props: {
    prefilledUsername: {
      type: String,
      default: null
    },
    isCaptchaActive: {
      type: Boolean,
      default: false
    },
    mtCaptchaTestKey: {
      type: String,
      default: null
    },
    isHintLocked: {
      type: Boolean,
      default: false
    },
    logoUri: {
      type: String,
      default: null
    },
    clientName: {
      type: String,
      default: null
    }
  },

  components: {
    Input,
    PasswordInput,
    Button,
    Link,
    Preloader,
    Page,
    Captcha,
    ModalEmailAlreadyInUse
  },

  setup(props) {
    const images = {
      arrow: arrowIcon,
      userIcon
    }
    const antiForgeryField = document.querySelector('input[name=__RequestVerificationToken]')
    const backendErrorElement = document.querySelector('#backend-error li')
    const backendErrorMessage = backendErrorElement && backendErrorElement.innerText
    const antiForgeryToken = antiForgeryField && antiForgeryField.value
    const urlParams = new URLSearchParams(window.location.search)
    const returnUrl = urlParams.get('returnUrl') || urlParams.get('ReturnUrl')
    const validations = {
      username: [validateRequired, validateEmail],
      password: validateRequired
    }
    const emailField = ref(null)
    const passwordField = ref(null)
    const signInForm = ref(null)
    const sInternalForm = ref(null)
    const isLoading = ref(false)
    const step = ref('email')
    const modals = reactive({
      emailAlreadyInUse: false
    })
    const fields = reactive({
      username: null,
      password: null,
      provider: null
    })
    const errors = reactive({
      username: '',
      password: '',
      captcha: ''
    })
    const showSignUp = computed(() => {
      const currentEnv = getEnv()

      return currentEnv !== 'production' && currentEnv !== 'staging'
    })
    const action = computed(() => {
      let url = '/Account/Login'

      if (returnUrl) {
        url = `${url}?returnUrl=${encodeURIComponent(returnUrl)}`
      }

      return url
    })
    const sInternalAction = computed(() => {
      let url = '/Account/ExternalLogin'

      if (returnUrl) {
        url = `${url}?returnUrl=${encodeURIComponent(returnUrl)}`
      }

      return url
    })

    const emailFieldDisabled = computed(
      () => !!props.prefilledUsername && props.isHintLocked && !backendErrorMessage
    )
    const captchaToken = ref(null)

    const forgotPasswordLink = computed(() => {
      return fields.username
        ? `/account/forgotpassword?email=${fields.username}`
        : '/account/forgotpassword'
    })

    const onSSOSubmit = () => {
      isLoading.value = true

      sInternalForm.value.submit()
    }

    const validateFields = (items) => {
      let isError = false

      items.forEach((field) => {
        const error = validateField(fields[field], validations[field])

        if (error) {
          errors[field] = error

          isError = true
        }
      })

      return isError
    }

    const onNext = async () => {
      const isError = validateFields(['username'])

      if (!isError) {
        const loginProvider = await Api.getLoginProvider(fields.username)
        const pattern = /@3shape\.com/gi
        const is3ShapeUser = pattern.test(fields.username)

        const aspenEmailPattern = /@aspendental\.com/gi
        const isAspenUser = aspenEmailPattern.test(fields.username) && getEnv() === 'test'

        if (is3ShapeUser) {
          step.value = 'sso'
          fields.provider = '3sinternal'
        } else if (isAspenUser) {
          step.value = 'sso'
          fields.provider = 'Microsoft'
        } else if (loginProvider.data !== 'password') {
          step.value = 'sso'
          fields.provider = loginProvider.data
        } else {
          step.value = 'password'

          await nextTick()

          passwordField.value.$el.childNodes[0].childNodes[1].focus()
        }
      }
    }

    const toggleModal = (modal) => {
      modals[modal] = !modals[modal]
    }

    onMounted(() => {
      window.addEventListener('keydown', (event) => {
        if (step.value === 'sso' && event.code === 'Enter') {
          onSSOSubmit()
        }
      })

      if (backendErrorMessage) {
        if (backendErrorMessage === 'Email already in use') {
          toggleModal('emailAlreadyInUse')
        } else {
          errors.username = backendErrorMessage
        }

        fields.username = sessionStorage.getItem('username')
      } else if (props.prefilledUsername) {
        fields.username = props.prefilledUsername

        if (props.isHintLocked) {
          onNext()
        }
      }

      emailField.value.$el.childNodes[1].focus()
    })

    const onInputChange = (event) => {
      const { value, name } = event.target

      fields[name] = value

      if (errors[name]) {
        errors[name] = ''
      }
    }

    const onInputBlur = (event) => {
      const { value, name } = event.target

      if (fields[name] !== null) {
        errors[name] = validateField(value, validations[name])
      }
    }

    const onCaptchaUpdated = (token) => {
      captchaToken.value = token

      errors.captcha = validateField(captchaToken.value, validateCaptchaTokenExpired)
    }

    const validateCaptcha = () => {
      const error = validateField(captchaToken.value, validateCaptchaTokenExists)

      if (error) {
        errors.captcha = error
      }

      return error === ''
    }

    const onSubmit = (event) => {
      let isError = false

      if (validateFields(Object.keys(fields)) || isError) {
        isError = true
      }

      const isCaptchaVisible = window.mtcaptcha && window.mtcaptcha.getStatus().isVisible
      if (props.isCaptchaActive && isCaptchaVisible && !validateCaptcha()) {
        isError = true
      }

      if (!isError) {
        isLoading.value = true

        sessionStorage.setItem('username', fields.username)

        signInForm.value.submit()
      }

      event.preventDefault()
    }

    const onBackButtonClick = () => {
      step.value = 'email'
    }

    const onLoginWithoutSSO = (event) => {
      event.preventDefault()

      step.value = 'password'
    }

    return {
      images,
      antiForgeryToken,
      emailField,
      passwordField,
      signInForm,
      sInternalForm,
      isLoading,
      step,
      modals,
      fields,
      errors,
      showSignUp,
      action,
      sInternalAction,
      emailFieldDisabled,
      captchaToken,
      forgotPasswordLink,
      toggleModal,
      onInputChange,
      onInputBlur,
      onNext,
      onSSOSubmit,
      onSubmit,
      onBackButtonClick,
      onCaptchaUpdated,
      onLoginWithoutSSO
    }
  }
}
