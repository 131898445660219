<template>
  <Modal :on-close="onClose">
    <div class="modal-recommended-auth-apps">
      <div class="modal-recommended-auth-apps__title">Recommended authenticator apps</div>

      <div class="modal-recommended-auth-apps__text">
        We recommend Google Authenticator or Microsoft Authenticator.
        <br />
        <b>Choose below</b>
        and
        <b>scan the QR code</b>
        with your phone camera to download:
      </div>

      <div class="modal-recommended-auth-apps__container">
        <div class="modal-recommended-auth-apps__item">
          <div>
            <div
              v-for="option in options"
              :key="apps[option].value"
              class="modal-recommended-auth-apps__radio-container"
            >
              <Radio
                :label="apps[option].label"
                name="app"
                :value="apps[option].value"
                :checked="selectedApp === apps[option].value"
                :onChange="handleRadioChange"
              />
            </div>
          </div>
        </div>
        <div class="modal-recommended-auth-apps__item">
          <div class="modal-recommended-auth-apps__links-container">
            <div class="modal-recommended-auth-apps__qr-code-text">Scan with camera:</div>
            <div class="modal-recommended-auth-apps__qr-code-container">
              <div class="modal-recommended-auth-apps__qr-code-label">
                {{ apps[selectedApp].label }}
              </div>
              <div class="modal-recommended-auth-apps__qr-icon-container">
                <img
                  :src="apps[selectedApp].os[operatingSystem].qrCodeIcon"
                  alt=""
                />
              </div>
              <a
                :href="apps[selectedApp].os[operatingSystem].link"
                target="_blank"
              >
                <img
                  :src="images[operatingSystem]"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-recommended-auth-apps__button-container">
        <Button
          variant="outlined"
          :on-click="onClose"
        >
          Close
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script src="./ModalRecommendedAuthApps.js"></script>

<style src="./ModalRecommendedAuthApps.scss" lang="scss" scoped></style>
